.task-row {
    opacity: .9;
}
.task-row:hover {
    opacity: 1;
    cursor: pointer;
}

.Completed {
    background-color: #68bd457d; 
}

.InProgress {
    background-color: #68b2e387; 
}

.Assigned {
    background-color: #ffc10726;
}

.Cancelled {
    background-color: #faa;
}