.main-footer {
    background: #fff;
    padding: 5px;
    color: #444;
    border-top: 1px solid #d2d6de;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9999999;
}
 