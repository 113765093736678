.container {
    position: relative;
    height: calc(100% - 165px);
    margin: 20px auto;
    margin-bottom: 75px;
}

.updateNotice {
    padding-top: 5px;
    height: 30px;
    background-color: #BE1E2D;
    color: #cccccc;
    width: 640px;
    position: relative;
    margin: 0 auto;
}

.parentUpdateNotice {
    text-align: center;
    width:100%
}

.linkColor {
    color: #cccccc;
    text-decoration: underline;
}

.linkColor:Hover {
    color: #cccccc;
    text-decoration: underline;
}

.active{
    background-color: #f3f9fd !important;
}