.not-uploaded {
    color: gray;
}

.uploading {
    color: blue;
}

.finishing {
    color: gray;
}

.uploaded {
    color: green;
}

.creationDate {
    text-transform: capitalize;
}

.actionColumn{
    min-width: 120px;
}