.rc-time-picker {
    width: 100%;
}

.rc-time-picker-input {
    font-size: 1rem;
    height: inherit;
}

.rc-time-picker-clear {
    top: 6px;
}
.react-datepicker-wrapper{
    display: block;
}
.react-datepicker__input-container{
    width: 100%;
}

.react-datepicker__input-container input{
    color: #666;
}
