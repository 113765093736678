.operation-date-picker {
    width: 100%;
    border: 1px solid #b8b8b7;
    background-color: #ffffff;
    line-height: 20px;
    font-size: 16px;
    height: 50px;
    padding: 14px;
    margin-bottom: 4px;
    margin-top: .25rem!important;
}
