.background {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: -1;
    background-size: cover;
    background-image: url(/image/login.jpg);
    opacity: 0.7;
}

.loginForm{
    min-height: 600px;
}
