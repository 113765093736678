table > thead > tr {
    color: #fff;
    background-color: #002B45;
}
table{
    background-color: #fff;
    cursor: pointer;
}
.table-hover tbody tr:hover{
    color: #fff;
    background-color: rgb(104,178,227);
}