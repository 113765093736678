.spinnerContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 40px;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: #ccc;
  margin: auto;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: wait;
  opacity: 0.7;
}

.lds-ring {
  display: inline-block;
  position: relative;
  min-width: 30px;
  min-height: 30px;
  min-width: 64px;
  max-height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  min-width: 25px;
  min-height: 25px;
  max-width: 51px;
  max-height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}