.booking-result{
    background-color: #e8fff1;
    padding: 2em;
}
.formField .formField {
    float: left;
    width: 50%;
    padding: 0em 1em 1.5em 0em;
}

fieldset .formField:first-child {
    padding: 0em 0em 1.5em 0em !important;
    width: 100%;
}

fieldset .formField:nth-child(odd) {
    padding: 0em 0em 1.5em 1em;
}

fieldset .formField:nth-child(even) {
    clear: left;
}

form>p {
    padding: 1em;
}

span.change-date{
    font-size: 3.2em;
    cursor: pointer;
}
span.change-date.disabled{
    color:#ccc;
}
span.change-date.disabled i{
    cursor: not-allowed;
}

.arrived-booking{
    background-color: #68BD45;
    color:white;
}