.xms-page-header img{
    width: 20px;
}

.xms-page-header h5{
    margin: 0;
    padding: 0.375rem 0.75rem;
}

.xms-align-center {
    display: flex;
    align-items: center;
}

.xms-margin-auto {
    margin: auto;
}