/* We'r using react-jsonschema-form package and this package is based on Bootstrap 3.3.6.
However our project use Bootstrap 4+. Therefore some class names are missing/changed. This css file just a workaround
for schema based components. You should load it if you are using dynamic schema based forms on your component.
Exp:


 */

label.radio-inline {
    margin-right: 1.5rem;
}

.radio-inline input[type="radio"] {
    margin-top: .3rem;
    margin-left: 0;
    margin-right: .3rem;
}