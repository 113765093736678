.inputLabel {
    width: 100px;
    text-align: center;
    vertical-align: middle;
}

.input-small {
    max-width: 300px;
    margin-left: 10px;
    margin-right: 10px;
    display: inline;
}

.result-card-list {
    margin-top: 15px;
}

.shipment-card {
    display: inline-block;
    width: 95%;
}

.shipment-arrow {
    height: 50px;
    display: inline-block;
    width: 5%;
}

.shipment-url {
    text-align: right;
}

.transport-info-span {
    margin-left: 10px;
    margin-top: 5px;
}

/* //////////////////////////////////////// */

.collapseArrow {
    width: 20px;
    margin-top: 10px;
}

.missingDocumentIcon {
    width: 30px;
    height: 30px;
    transform: rotate(0deg) !important;
}

.unit-transport-shipment-item {
    padding-left: 100px;
    border-radius: 0;
    background: #00000004;
}

.unit-transport-shipment-item:hover {
    border: 1px solid #ccc;
    background-color: #00000009;
    cursor: pointer;
}

.unit-shipment-warning-field {
    min-width: 78px;
}