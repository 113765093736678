.timeSlot {
    border: 1px solid rgb(204, 204, 204);
    height: 4rem;
    font-size: 1.3rem;
    cursor: pointer;
    color: rgb(0, 43, 69);
    font-weight: bold;
    background-color: #c3e0f4;
}

.selected {
    background-color: #68BD45;
}

.timeSlot:hover {
    color: whitesmoke;
    background-color: rgb(0, 43, 69);
}

.unAvailableTimeSlot {
    cursor: not-allowed;
    color: whitesmoke;
    background-color: #CCC;
}

.unAvailableTimeSlot:hover {
    color: whitesmoke;
    background-color: #CCC;
}